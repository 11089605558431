/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


var num = 100; //number of pixels before modifying styles

$(window).bind('scroll', function () {
    if ($(window).scrollTop() > num) {
        $('#main-header').addClass('fixed');
    } else {
        $('#main-header').removeClass('fixed');
    }
});

//         if ($(window).width() > 992) {
//           $(window).scroll(function(){  
//            if ($(this).scrollTop() > 600) {
//             $('#navbar_top').addClass("d-active");
//         // add padding top to show content behind navbar
//         // $('body').css('padding-top', $('.navbar').outerHeight() + 'px');
//       }else{
//         $('#navbar_top').removeClass("d-active");
//          // remove padding top from body
//          // $('body').css('padding-top', '0');
//        }   
//      });
// } // end if


        // $('#myTab a').on('click', function (e) {
        //   e.preventDefault();
        //   $(this).tab('show');
        // });



        // $("#mobile-menu .collapsed").click(function(){
        //   $(".collapse").collapse('toggle');
        //   $("#search").toggleClass('active');
        //   return false;
        // });




        // $('.dropdown-toggle').dropdown();

        // $('#tabs-mobile .nav-tabs').on('shown.bs.tab', 'a', function (e) {
        //   if (e.relatedTarget) {
        //     $(e.relatedTarget).removeClass('active');
        //   }
        // });



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {


///////////////// fixed menu on scroll for desktop
if ($(window).width() > 992) {
  $(window).scroll(function(){  
   if ($(this).scrollTop() > 630) {
    $('#navbar_top').addClass("d-active");
        // add padding top to show content behind navbar
        // $('body').css('padding-top', $('.navbar').outerHeight() + 'px');
      }else{
        $('#navbar_top').removeClass("d-active");
         // remove padding top from body
         // $('body').css('padding-top', '0');
       }   
     });
} // end if

},
finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },    

    'accelerator': {
      init: function() {

        $('.responsive').slick({
         slidesToShow: 3,
         slidesToScroll: 1,
         autoplay: true,
         autoplaySpeed: 2000,
         arrows:true,
         responsive: [
         {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        ]
      });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },    


    'research': {
      init: function() {

        $('.responsive').slick({
         slidesToShow: 3,
         slidesToScroll: 1,
         autoplay: true,
         autoplaySpeed: 2000,
         arrows:true,
         responsive: [
         {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        ]
      });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    

    'college_innovation_network': {
      init: function() {

        $('.responsive').slick({
         slidesToShow: 3,
         slidesToScroll: 1,
         autoplay: true,
         autoplaySpeed: 2000,
         arrows:true,
         responsive: [
         {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        ]
      });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },



    'blog': {
      init: function() {

       $(document).ready(function(){


        if(jQuery("#load-more-posts-btn").length){
          var posts = jQuery("#load-more-posts-btn");
          posts.on("click", function() {
            var portofolio = jQuery(".posts");
            portofolio.children(":hidden").show(400);
            posts.hide();
          });
        }


        if(jQuery("#load-more-news-btn").length)
        {
          var allworks = jQuery("#load-more-news-btn");
          allworks.on("click", function() {
            var portofolio = jQuery("#news");
            portofolio.children(":hidden").show(400);
            allworks.hide();
          });
        }



      });
     },
     finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },







    // About us page, note the change from about-us to about_us.
    'page_template_template_program_page': {
      init: function() {
        // JavaScript to be fired on the about us page

        $(document).ready(function(){
          $('.customer-logos').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 1500,
            arrows: true,
            dots: true,
            infinite: false,
            pauseOnHover: false,
            responsive: [{
              breakpoint: 768,
              settings: {
                slidesToShow: 2
              }
            }, 
            {
              breakpoint: 520,
              settings: {
                slidesToShow: 2
              }
            }]
          });   

          $('.modal-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplaySpeed: 1000,
            autoplay: true,
            arrows: false,
            dots: true,
            pauseOnHover: false,
            responsive: [{
              breakpoint: 768,
              settings: {
                slidesToShow: 1
              }
            }, 
            {
              breakpoint: 520,
              settings: {
                slidesToShow: 1
              }
            }]
          });


          $('.occupation-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplaySpeed: 5000,
            autoplay: true,
            arrows: true,
            prevArrow:"<button type='button' class='slick-prev pull-left'><i class='fa fa-angle-left' aria-hidden='true'></i></button>",
            nextArrow:"<button type='button' class='slick-next pull-right'><i class='fa fa-angle-right' aria-hidden='true'></i></button>",
            dots: false,
            pauseOnHover: false,
            responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true
              }
            },
            {
              breakpoint: 768,
              settings: {
                draggable: true,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                draggable: true,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                draggable: true,
                slidesToScroll: 1
              }
            }

            ]
          });






        });


        /*begin show 2nd menu when you scrool down*/
        var elementPosition = $('#second-menu').offset();
        console.log(elementPosition);

        $(window).scroll(function(){
          // if($(window).scrollTop() > elementPosition.top){
            if($(window).scrollTop() > 670 ){
              $('#second-menu').addClass('position-fixed');
            } else {
              $('#second-menu').removeClass('position-fixed');
            }    
          });
        /*end show 2nd menu when you scrool down*/


        /*begin Simple Typing Carousel*/
        var TxtRotate = function(el, toRotate, period) {
          this.toRotate = toRotate;
          this.el = el;
          this.loopNum = 0;
          this.period = parseInt(period, 10) || 2000;
          this.txt = '';
          this.tick();
          this.isDeleting = false;
        };

        TxtRotate.prototype.tick = function() {
          var i = this.loopNum % this.toRotate.length;
          var fullTxt = this.toRotate[i];

          if (this.isDeleting) {
            this.txt = fullTxt.substring(0, this.txt.length - 1);
          } else {
            this.txt = fullTxt.substring(0, this.txt.length + 1);
          }

          this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';

          var that = this;
          var delta = 300 - Math.random() * 100;

          if (this.isDeleting) { delta /= 2; }

          if (!this.isDeleting && this.txt === fullTxt) {
            delta = this.period;
            this.isDeleting = true;
          } else if (this.isDeleting && this.txt === '') {
            this.isDeleting = false;
            this.loopNum++;
            delta = 500;
          }

          setTimeout(function() {
            that.tick();
          }, delta);
        };

        window.onload = function() {
          var elements = document.getElementsByClassName('txt-rotate');
          for (var i=0; i<elements.length; i++) {
            var toRotate = elements[i].getAttribute('data-rotate');
            var period = elements[i].getAttribute('data-period');
            if (toRotate) {
              new TxtRotate(elements[i], JSON.parse(toRotate), period);
            }
          }
  // INJECT CSS
  var css = document.createElement("style");
  css.type = "text/css";
  css.innerHTML = ".txt-rotate > .wrap { border-right: 0.08em solid #666 }";
  document.body.appendChild(css);
};

/*end*/


/*added different class, after I access the first tab */
// $('#top a[href="#first-tab"]').on('click', function (e) {
//   e.preventDefault()
//   $('#top .align-items-stretch').removeClass('align-items-stretch').addClass('align-items-center');
// })
/*end added class*/


}
},

finalize: function() {

}


};

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
